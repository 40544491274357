// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cKGinvgRhfj2RP5WEUXh,.nvWRRVzdiyqDxrL1A1oJ,.gTzb83hvJjwXccd47HX2{padding:1.25rem .8125rem;text-align:left;font-size:.75rem;margin:0 0 1.875rem;border-radius:.1875rem;line-height:1.4}.gTzb83hvJjwXccd47HX2{color:#ff1e1e;background-color:#ffc6c6;border:solid .0625rem #ff9595}.nvWRRVzdiyqDxrL1A1oJ{color:#ff1e1e;padding:.8125rem 1.25rem;background-color:#fff9f9;border:solid .0625rem #ffd0d0;box-shadow:0 .125rem .25rem 0 rgba(255,208,208,.54)}.cKGinvgRhfj2RP5WEUXh{color:#fff;background-color:#5aca8f;border:solid .0625rem #34b973}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/MessageBox/styles.scss"],"names":[],"mappings":"AAAA,kEACE,wBAAA,CACA,eAAA,CACA,gBAAA,CACA,mBAAA,CACA,sBAAA,CACA,eAAA,CAGF,sBAGE,aAAA,CACA,wBAAA,CACA,6BAAA,CAGF,sBAGE,aAAA,CACA,wBAAA,CACA,wBAAA,CACA,6BAAA,CACA,mDAAA,CAGF,sBAGE,UAAA,CACA,wBAAA,CACA,6BAAA","sourcesContent":["%msg-base {\n  padding: 1.25rem 0.8125rem;\n  text-align: left;\n  font-size: 0.75rem;\n  margin: 0 0 1.875rem;\n  border-radius: 0.1875rem;\n  line-height: 1.4;\n}\n\n.error {\n  @extend %msg-base;\n\n  color: #ff1e1e;\n  background-color: #ffc6c6;\n  border: solid 0.0625rem #ff9595;\n}\n\n.light {\n  @extend %msg-base;\n\n  color: #ff1e1e;\n  padding: 0.8125rem 1.25rem;\n  background-color: #fff9f9;\n  border: solid 0.0625rem #ffd0d0;\n  box-shadow: 0 0.125rem 0.25rem 0 rgba(255, 208, 208, .54);\n}\n\n.success {\n  @extend %msg-base;\n\n  color: #fff;\n  background-color: #5aca8f;\n  border: solid 0.0625rem #34b973;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success": "cKGinvgRhfj2RP5WEUXh",
	"light": "nvWRRVzdiyqDxrL1A1oJ",
	"error": "gTzb83hvJjwXccd47HX2"
};
export default ___CSS_LOADER_EXPORT___;
