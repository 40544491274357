// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UwVYwtusmqSN9GQQEHDh{width:100%;background:#fff}@keyframes Oevox4a3tj4xspNtxD_s{0%{background-position:-41rem 0}100%{background-position:41rem 0}}.vH5ctV7NlEN5GxwJOoXf,.MxS7mbQj1IM1BGAk5TBO{height:.9375rem;border-radius:.1875rem;background:linear-gradient(to right, rgba(226, 226, 226, 0.5) 0, rgba(226, 226, 226, 0.2) 35%, rgba(226, 226, 226, 0.5) 70%);animation:Oevox4a3tj4xspNtxD_s 2s linear infinite forwards}.MxS7mbQj1IM1BGAk5TBO{width:35%;margin:1.875rem 0 .625rem}.vH5ctV7NlEN5GxwJOoXf{width:100%;margin:.3125rem 0}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/DocumentPreviewer/PreviewOverlay/PreviewContent/Placeholder/styles.scss"],"names":[],"mappings":"AAGA,sBACE,UAAA,CACA,eAAA,CAEA,gCACE,GACE,4BAAA,CAEF,KACE,2BAAA,CAAA,CAKN,4CACE,eAAA,CACA,sBAAA,CACA,4HAAA,CAIA,0DAAA,CAGF,sBAGE,SAAA,CACA,yBAAA,CAGF,sBAGE,UAAA,CACA,iBAAA","sourcesContent":["@import '../../mixins';\n\n\n.root {\n  width: 100%;\n  background: #fff;\n\n  @keyframes wave {\n    0% {\n      background-position: -41rem 0;\n    }\n    100% {\n      background-position: 41rem 0;\n    }\n  }\n}\n\n%fake-style {\n  height: 0.9375rem;\n  border-radius: 0.1875rem;\n  background: linear-gradient(to right,\n                              rgba(#e2e2e2, .5) 0,\n                              rgba(#e2e2e2, .2) 35%,\n                              rgba(#e2e2e2, .5) 70%);\n  animation: wave 2s linear infinite forwards;\n}\n\n.heading {\n  @extend %fake-style;\n\n  width: 35%;\n  margin: 1.875rem 0 0.625rem;\n}\n\n.paragraph {\n  @extend %fake-style;\n\n  width: 100%;\n  margin: 0.3125rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "UwVYwtusmqSN9GQQEHDh",
	"paragraph": "vH5ctV7NlEN5GxwJOoXf",
	"heading": "MxS7mbQj1IM1BGAk5TBO",
	"wave": "Oevox4a3tj4xspNtxD_s"
};
export default ___CSS_LOADER_EXPORT___;
