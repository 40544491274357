// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XHUjWPCNh7Enaw9qu8iI{display:flex;height:100%;justify-content:space-between;align-items:center;height:3.75rem;column-gap:1.5625rem}.hkD9tJECDJtlYR06mCU4{width:15.125rem;display:flex}.Ph9axOXhHiy2poiBRwNo{height:1.875rem;width:auto;margin-right:1rem}.eHGSauiyuyQYJlQJlTkN{flex:1;padding-left:2.5625rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/Hat/styles.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,6BAAA,CACA,kBAAA,CACA,cAAA,CACA,oBAAA,CAGF,sBAME,eAAA,CACA,YAAA,CAGF,sBACE,eAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,MAAA,CACA,sBAAA","sourcesContent":[".root {\n  display: flex;\n  height: 100%;\n  justify-content: space-between;\n  align-items: center;\n  height: 3.75rem;\n  column-gap: 1.5625rem;\n}\n\n.leftContent {\n  // TODO: these values will be carried over once we get the parent layout component\n  // (the overall page layout) genericized and brought over into @termly_web/common\n  // width: $sidebar-width - $outer-vertical-margin + $sidebar-margin-right;\n  // We'll likely be using CSS variables.\n  // For now, this value suffices for the builder header.\n  width: 15.125rem;\n  display: flex;\n}\n\n.logo {\n  height: 1.875rem;\n  width: auto;\n  margin-right: 1rem;\n}\n\n.middleContent {\n  flex: 1;\n  padding-left: 2.5625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "XHUjWPCNh7Enaw9qu8iI",
	"leftContent": "hkD9tJECDJtlYR06mCU4",
	"logo": "Ph9axOXhHiy2poiBRwNo",
	"middleContent": "eHGSauiyuyQYJlQJlTkN"
};
export default ___CSS_LOADER_EXPORT___;
