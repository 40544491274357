// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WV9I0Wa518W1Zucc47NU{white-space:nowrap}.rh0LAnmYTD9CBixmetOU{color:#00c999 !important;font-size:1rem !important;font-weight:700;text-transform:uppercase;margin-left:1rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/BuildVersionChecker/RefreshPrompt/styles.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,wBAAA,CACA,yBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".text {\n  white-space: nowrap;\n}\n\n.button {\n  color: #00c999 !important;\n  font-size: 1rem !important;\n  font-weight: 700;\n  text-transform: uppercase;\n  margin-left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "WV9I0Wa518W1Zucc47NU",
	"button": "rh0LAnmYTD9CBixmetOU"
};
export default ___CSS_LOADER_EXPORT___;
