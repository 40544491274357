// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VDsgB7qiSNGevDO45ynJ .Ap2HfsO5ZdhAppkS4R1Q{transition:transform .2s ease !important}.VDsgB7qiSNGevDO45ynJ .Ap2HfsO5ZdhAppkS4R1Q.ZEC9qQg2quZwp1yfzmDZ{transform:rotate(180deg)}.VDsgB7qiSNGevDO45ynJ .L6NJsRrpPcOSxF33G6UC{color:#000}.VDsgB7qiSNGevDO45ynJ .L6NJsRrpPcOSxF33G6UC.EXejnewbCsf7cTnk5HR2{opacity:.4}.VDsgB7qiSNGevDO45ynJ .L6NJsRrpPcOSxF33G6UC.sSEjCbbN_3guvTz9atoe,.VDsgB7qiSNGevDO45ynJ .L6NJsRrpPcOSxF33G6UC.O2wwllk3nH8SBhYvOdo9{background-color:#f5f6fa}.VDsgB7qiSNGevDO45ynJ .L6NJsRrpPcOSxF33G6UC:active:not(.EXejnewbCsf7cTnk5HR2){background-color:#b2d4ff}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/Select/styles.scss"],"names":[],"mappings":"AAOE,4CACE,wCAAA,CAEA,iEACE,wBAAA,CAIJ,4CACE,UAAA,CAEA,iEACE,UAAA,CAGF,kIAGE,wBAAA,CAGF,8EACE,wBAAA","sourcesContent":["// Normally, I don't like to nest rules, but the alternative is either\n// to use `!important` or to make sure that the consumers of this\n// component have their Emotion cache set to prepend <style>s to the <head>\n// rather than append them--neither of which give me the warm-and-fuzzies.\n// See https://github.com/JedWatson/react-select/issues/4953#issuecomment-991059178\n//\n.root {\n  .dropdownIndicator {\n    transition: transform .2s ease !important;\n\n    &.isOpen {\n      transform: rotate(180deg);\n    }\n  }\n\n  .option {\n    color: #000;\n\n    &.isDisabled {\n      opacity: 0.4;\n    }\n\n    &.isFocused,\n    &.isSelected\n    {\n      background-color: #f5f6fa;\n    }\n\n    &:active:not(.isDisabled) {\n      background-color: #b2d4ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VDsgB7qiSNGevDO45ynJ",
	"dropdownIndicator": "Ap2HfsO5ZdhAppkS4R1Q",
	"isOpen": "ZEC9qQg2quZwp1yfzmDZ",
	"option": "L6NJsRrpPcOSxF33G6UC",
	"isDisabled": "EXejnewbCsf7cTnk5HR2",
	"isFocused": "sSEjCbbN_3guvTz9atoe",
	"isSelected": "O2wwllk3nH8SBhYvOdo9"
};
export default ___CSS_LOADER_EXPORT___;
