import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import PropTypes from 'prop-types'
import IframeResizer from 'iframe-resizer-react'

import { Loading } from '@termly_web/common'

import { currentDomain } from './lib/helpers'

import Styles from './styles.module.scss'


export default function Iframe({ id }) {
  const [isLoading, setLoading] = useState(true)

  const src = useMemo(() => {
    return `${ currentDomain() }/policy-viewer/iframe-content.html?policyUUID=${ id }`
  }, [id])

  const onResized = useCallback((event) => {
    if ( event.type !== 'mutationObserver' ) {
      return
    }

    setLoading(false)
  }, [setLoading])

  return (
    <>
      { renderLoadSpinner(isLoading) }
      <IframeResizer
        allowFullScreen
        inPageLinks
        checkOrigin={ false }
        className={ Styles.root }
        data-testid="policy-iframe"
        frameBorder="0"
        heightCalculationMethod="taggedElement"
        id={ id }
        log={ false }
        onResized={ onResized }
        scrolling="no"
        src={ src }
        title="Termly Policy"
      >
        <p>Your browser does not support iframes.</p>
      </IframeResizer>
    </>
  )
}

Iframe.propTypes = {
  id: PropTypes.string.isRequired,
}

function renderLoadSpinner(isLoading) {
  if ( !isLoading ) {
    return null
  }

  return (
    <div data-testid="policy-loading-spinner">
      <Loading />
    </div>
  )
}
