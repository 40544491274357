// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b8BN_yY3XPLaPLPUrDtp{color:#a00}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/Required/styles.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".root {\n  color: #a00;\n}     \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "b8BN_yY3XPLaPLPUrDtp"
};
export default ___CSS_LOADER_EXPORT___;
