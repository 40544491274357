// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../css-loader-virtual-e1279b608a/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ponkb_4i6_25GaPiR9Dc{--previewer-background-color: #f5f6fa}.JfL_nbEFyiRknhoI6s2g{background:#f5f6fa;border:1px solid #e8e8e8;border-radius:.25rem;box-shadow:0px 2px 4px rgba(226,226,226,.5);margin-bottom:1rem}.ssB5hpHWukmSxrAAzLNH{text-transform:uppercase;font-weight:500;display:flex;align-items:center;justify-content:center;width:100%;line-height:1.25rem}.lsecI0Z5ezYMwf1ohbAz{height:1.25rem;width:1.25rem;margin-right:.25rem}", "",{"version":3,"sources":["webpack://./../../.yarn/__virtual__/@termly_web-common-virtual-789e725916/1/client/common/src/components/Previewer/styles.scss"],"names":[],"mappings":"AAEA,sBACE,qCAAA,CAGF,sBACE,kBAPiB,CAQjB,wBAAA,CACA,oBAAA,CACA,2CAAA,CACA,kBAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,mBAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,mBAAA","sourcesContent":["$background-color: #f5f6fa;\n\n.root {\n  --previewer-background-color: #{ $background-color };\n}\n\n.previewBox {\n  background: $background-color;\n  border: 1px solid #e8e8e8;\n  border-radius: 0.25rem;\n  box-shadow: 0px 2px 4px rgba(226, 226, 226, 0.5);\n  margin-bottom: 1rem;\n}\n\n.button {\n  text-transform: uppercase;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  line-height: 1.25rem;\n}\n\n.previewButton__icon {\n  height: 1.25rem;\n  width: 1.25rem;\n  margin-right: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ponkb_4i6_25GaPiR9Dc",
	"previewBox": "JfL_nbEFyiRknhoI6s2g",
	"button": "ssB5hpHWukmSxrAAzLNH",
	"previewButtonIcon": "lsecI0Z5ezYMwf1ohbAz"
};
export default ___CSS_LOADER_EXPORT___;
